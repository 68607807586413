import React, {Component} from 'react';
import Form from '../components/register-form';
import {navigate} from 'gatsby';
import Seo from '../components/seo';

class RegisterPage extends Component {

  closeModalHandle(e) {
    e.preventDefault();
    navigate('/masterclass/');
  }

  render() {
    return (
      <>
        <Seo title={`P&P Barbers - Register your interest`} description={`Clean cuts, good times, friendly folks.`} keywords={'Barber, Hair cut, Fitzroy Barber, Shave, Barbershop'}/>
        <section className="models">
          <div className="models__wrapper">
            <Form />

            <div className="models-wrapper__close">
              <button onClick={this.closeModalHandle.bind(this)}>Back to Masterclass</button>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default RegisterPage;
