import React, {Component} from 'react';
import Logo from '../assets/images/pphh-logo-brown.svg';
import {navigate, Link} from 'gatsby';

function encode(data) {
  return Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
}

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit(e) {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': this.refs.form.getAttribute('name'),
        ...this.state,
      }),
    }).then(() => {
      this.setState({result: 'success'});
    }).catch(error => this.setState({result: 'fail'}));

    navigate('/thank-you');
  }

  render() {
    let props = {
      ref: 'form',
      name: 'register',
      className: 'form',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    return (
      <>
        <form {...props} onSubmit={this.handleSubmit} className={`form form-modal ${this.props.className}`}>
          <div className="form-wrapper">
            <Link to="/">
              <img className="img-fluid" src={Logo} alt="PPHH Barbers"/>
            </Link>

            <h2>Register you interest</h2>

            <div className="form-wrapper__input">
              <input onChange={this.handleChange} name="name" placeholder="Your name" type="text" required/>
              <input onChange={this.handleChange} name="email" placeholder="Your email" type="email" required/>
              <input onChange={this.handleChange} name="phone" placeholder="Contact number" type="phone" required/>
            </div>

            <p>What Modules are you interested in?</p>

            <div className="form-wrapper__checkbox">
              <div className="form-wrapper__checkbox--group">
                <div className="main">
                  <input onChange={this.handleChange} name="hands_on" id="hands-on" type="checkbox"/>
                  <label htmlFor="hands-on">Hands-on</label>
                </div>
              </div>

              <div className="form-wrapper__checkbox--group">

                <div className="main">
                  <input onChange={this.handleChange} name="look_and_learn" id="look-and-learn" type="checkbox"/>
                  <label htmlFor="look-and-learn">Look &amp; Learn</label>
                </div>
              </div>

              <div className="form-wrapper__checkbox--group">
                <div className="main">
                  <input onChange={this.handleChange} name="full_course" id="full-course" type="checkbox"/>
                  <label htmlFor="full-course">Full Course</label>
                </div>
              </div>
            </div>

            <button className="btn" type="submit">Submit</button>
          </div>
        </form>
      </>
    );
  }
}

export default RegisterForm;
